import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import testImg from 'images/lawyer-test.png'
import Awards from 'components/Awards'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {
  InnerWrapper,
  Name,
  Photo,
  PhotoWrapper,
  Position,
  SliderWrapper,
  TextWrapper,
  Title,
  Wrapper,
  FilterInner,
  FilterOuter,
  Container,
} from './IndexHero.styles'

const IndexHero = ({children, data, title, ...rest}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  }
  return (
    <Wrapper {...rest}>
      <FilterOuter />
      <Container>
        <InnerWrapper>
          <SliderWrapper>
            <Slider {...settings}>
              {data.map(item => (
                <PhotoWrapper key={item.node.name}>
                  <Name color="common.white" fontWeight={700}>
                    {item.node.name}
                  </Name>
                  <Position color="common.white" size="xs">
                    {item.node.position[language]}
                  </Position>
                  <Photo src={item.node.photo.url} />
                  <FilterInner />
                </PhotoWrapper>
              ))}
            </Slider>
          </SliderWrapper>
          <TextWrapper>
            <Title content={title} size="title" component="h1" color="common.white" />
            <Awards />
          </TextWrapper>
        </InnerWrapper>
      </Container>
    </Wrapper>
  )
}

IndexHero.propTypes = {
  data: PropTypes.array,
}
IndexHero.defaultProps = {}

export default IndexHero
