import styled from 'styled-components'
import Text from 'components/Text'

export const Wrapper = styled.div(({theme}) => ({
  paddingBottom: 80,

  [theme.breakpoints.down('tablet')]: {
    paddingBottom: 60,
  },
}))

export const Title = styled(Text)(({theme}) => ({
  marginBottom: 30,
}))

export const Subtitle = styled(Text)(({theme}) => ({
  marginBottom: 0,
  maxWidth: 700,
}))
