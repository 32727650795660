import styled from 'styled-components'
import Text from 'components/Text'

export const Wrapper = styled.div(({theme}) => ({
  paddingTop: 80,
  background: theme.palette.grey[900],

  [theme.breakpoints.down('tablet')]: {
    paddingTop: 60,
  },
}))

export const Title = styled(Text)(({theme}) => ({
  marginBottom: 54,
  color: theme.palette.common.white,
}))

export const ItemsWrapper = styled.div(({theme}) => ({
  display: 'flex',

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
  },
}))

export const Item = styled.div(({theme}) => ({
  padding: '0 17px',
  maxWidth: 160,
  width: '100%',
  minHeight: 245,
  display: 'flex',
  flexDirection: 'column',
  borderLeft: '1px solid #5B5B5B',
  borderRight: '1px solid #5B5B5B',

  '&:last-of-type': {
    borderRight: 'none',
  },

  [theme.breakpoints.down('tablet')]: {
    padding: '35px 0',
    maxWidth: '100%',
    minHeight: 'auto',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid #5B5B5B',
  },
}))

export const Number = styled(Text)(({theme}) => ({
  marginBottom: 15,
}))

export const ItemText = styled(Text)(({theme}) => ({
  textTransform: 'uppercase',
  fontWeight: 700,
}))
