import styled from 'styled-components'
import {Link} from 'gatsby'
import Text from 'components/Text'
import Arrow from 'images/icons/nav-item-arrow.inline.svg'

export const Wrapper = styled(Link)(({theme}) => ({
  marginBottom: 30,
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 375,
  width: '100%',
  minHeight: 250,
  backgroundColor: theme.palette.common.white,
  padding: '35px 30px 25px 30px',
}))

export const Title = styled(Text)(({theme}) => ({
  textTransform: 'uppercase',
  marginBottom: 30,
}))

export const Subtitle = styled(Text)(({theme}) => ({
  fontSize: theme.fontSizes.md,
  textTransform: 'uppercase',
}))

export const Bottom = styled.div(({theme}) => ({}))

export const ServiceItems = styled.div(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const ServiceItem = styled.div(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%'
}))

export const ServiceText = styled.div(({theme}) => ({
  width: '100%'
}))

export const ServiceItemArrow = styled(Arrow)(({theme}) => ({
  marginRight: 20,
  width: 5,
  height: 8,
}))
