import styled from 'styled-components'
import Text from 'components/Text'

export const Wrapper = styled.div(({theme}) => ({
  paddingTop: 80,
  paddingBottom: 60,
  background: theme.palette.primary.bg,

  [theme.breakpoints.down('tablet')]: {
    padding: '60px 0',
  },
}))

export const TitleWrapper = styled.div(({theme}) => ({
  maxWidth: 700,
}))

export const Subtitle = styled(Text)(({theme}) => ({
  marginBottom: 80,
  fontSize: theme.fontSizes.md,

  [theme.breakpoints.down('tablet')]: {
    marginBottom: 60,
  },
}))

export const OuterWrapper = styled.div(({theme}) => ({
  [theme.breakpoints.down('tablet')]: {
    margin: '0 -15px',
  },
}))

export const InnerWrapper = styled.div(({theme}) => ({
  maxWidth: 770,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
  },
}))
