import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {
  Bottom,
  ServiceItem,
  ServiceItemArrow,
  ServiceItems,
  Subtitle,
  Title,
  Wrapper,
  ServiceText,
} from './ServiceBlock.styles'

const ServiceBlock = ({children, title, serviceTypes, to, ...rest}) => {
  const {t, language} = useI18next()
  return (
    <Wrapper {...rest} to={`/services/${to}`}>
      <Title size="titleXs" fontWeight={700}>
        {title}
      </Title>
      <Bottom>
        <Subtitle content={t('sections.services.blockSubtitle')} fontWeight={700} mb={17} />
        <ServiceItems>
          {serviceTypes.map(item => (
            <ServiceItem key={item[language]}>
              <ServiceItemArrow />
              <ServiceText>{item[language]}</ServiceText>
            </ServiceItem>
          ))}
        </ServiceItems>
      </Bottom>
    </Wrapper>
  )
}

ServiceBlock.propTypes = {}
ServiceBlock.defaultProps = {}

export default ServiceBlock
