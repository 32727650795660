import React from 'react'
// import PropTypes from 'prop-types'
import {Wrapper, Title, Subtitle} from './AboutSection.styles'
import ContentContainer from '../../components/ContentContainer'

const AboutSection = ({children, ...rest}) => (
  <Wrapper {...rest}>
    <ContentContainer>
      <Title size="titleSm" content={rest.title} />
      <Subtitle size="def" content={rest.subtitle} />
    </ContentContainer>
  </Wrapper>
)

AboutSection.propTypes = {}
AboutSection.defaultProps = {}

export default AboutSection
