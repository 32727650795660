import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {Item, ItemsWrapper, ItemText, Number, Title, Wrapper} from './Statistics.styles'
import ContentContainer from '../../components/ContentContainer'

const Statistics = ({children, ...rest}) => {
  const {t} = useTranslation()
  return (
    <Wrapper {...rest}>
      <ContentContainer>
        <Title content={t('sections.statistics.title')} size="titleSm" color="common.white" />
        <ItemsWrapper>
          <Item>
            <Number content={t('sections.statistics.number1')} color="grey[700]" size="titleXL" />
            <ItemText content={t('sections.statistics.text1')} color="common.white" />
          </Item>
          <Item>
            <Number content={t('sections.statistics.number2')} color="grey[700]" size="titleXL" />
            <ItemText content={t('sections.statistics.text2')} color="common.white" />
          </Item>
          <Item>
            <Number content={t('sections.statistics.number3')} color="grey[700]" size="titleXL" />
            <ItemText content={t('sections.statistics.text3')} color="common.white" />
          </Item>
          <Item>
            <Number content={t('sections.statistics.number4')} color="grey[700]" size="titleXL" />
            <ItemText content={t('sections.statistics.text4')} color="common.white" />
          </Item>
          <Item>
            <Number content={t('sections.statistics.number5')} color="grey[700]" size="titleXL" />
            <ItemText content={t('sections.statistics.text5')} color="common.white" />
          </Item>
        </ItemsWrapper>
      </ContentContainer>
    </Wrapper>
  )
}

Statistics.propTypes = {}
Statistics.defaultProps = {}

export default Statistics
