import React from 'react'
// import PropTypes from 'prop-types'
import ContentContainer from 'components/ContentContainer'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {InnerWrapper, OuterWrapper, Subtitle, Wrapper, TitleWrapper} from './ServicesSection.styles'
import ServiceBlock from '../../components/ServiceBlock'
import Text from '../../components/Text'

const ServicesSection = ({children, data, title, subtitle, ...rest}) => {
  const {language} = useI18next()
  return (
    <Wrapper {...rest}>
      <ContentContainer>
        <TitleWrapper>
          <Text content={title} size="titleXs" mb={10} uppercase />
          <Subtitle content={subtitle} />
        </TitleWrapper>
        <OuterWrapper>
          <InnerWrapper>
            {data.map(item => (
              <ServiceBlock
                to={item.node.page_url}
                key={item.node.name[language]}
                title={item.node.name[language]}
                serviceTypes={item.node.service_types}
              />
            ))}
          </InnerWrapper>
        </OuterWrapper>
      </ContentContainer>
    </Wrapper>
  )
}

ServicesSection.propTypes = {}
ServicesSection.defaultProps = {}

export default ServicesSection
